module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-157516868-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#000000","display":"minimal-ui","icon":"src/images/framework/favicon.png","name":"Denizko","short_name":"Denizko","shortcuts":[{"description":"Denizko","name":"Denizko","url":"/"},{"description":"Denizko art shop","name":"Shop","url":"/shop"}],"start_url":"/","theme_color":"#cad3da","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"23ba176b1a6f2d943c79afa4de3cb00b"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
